// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bur-resources-tsx": () => import("./../../../src/pages/bur/resources.tsx" /* webpackChunkName: "component---src-pages-bur-resources-tsx" */),
  "component---src-pages-considering-hep-b-treatment-tsx": () => import("./../../../src/pages/considering-hep-b-treatment.tsx" /* webpackChunkName: "component---src-pages-considering-hep-b-treatment-tsx" */),
  "component---src-pages-hat-resources-tsx": () => import("./../../../src/pages/hat/resources.tsx" /* webpackChunkName: "component---src-pages-hat-resources-tsx" */),
  "component---src-pages-hep-b-testing-diagnosis-tsx": () => import("./../../../src/pages/hep-b-testing-diagnosis.tsx" /* webpackChunkName: "component---src-pages-hep-b-testing-diagnosis-tsx" */),
  "component---src-pages-hmn-resources-tsx": () => import("./../../../src/pages/hmn/resources.tsx" /* webpackChunkName: "component---src-pages-hmn-resources-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-khm-resources-tsx": () => import("./../../../src/pages/khm/resources.tsx" /* webpackChunkName: "component---src-pages-khm-resources-tsx" */),
  "component---src-pages-living-with-hep-b-tsx": () => import("./../../../src/pages/living-with-hep-b.tsx" /* webpackChunkName: "component---src-pages-living-with-hep-b-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-som-resources-tsx": () => import("./../../../src/pages/som/resources.tsx" /* webpackChunkName: "component---src-pages-som-resources-tsx" */),
  "component---src-pages-spa-resources-tsx": () => import("./../../../src/pages/spa/resources.tsx" /* webpackChunkName: "component---src-pages-spa-resources-tsx" */),
  "component---src-pages-what-is-hep-b-tsx": () => import("./../../../src/pages/what-is-hep-b.tsx" /* webpackChunkName: "component---src-pages-what-is-hep-b-tsx" */)
}

